<template>
  <div>
    <h3 class="text-center">Add Areas of Expertise</h3>
    <dir class="center-element">
      <img :src="areaOfExperties.icon" alt="" width="50%"/>
    </dir>

    <ImagPicker
      :imageData="{ maxNoImg: 1, imageNo: 0 }"
      class="margin-20-bottom"
    ></ImagPicker>
    <p v-if="areaOfExpertiesError.icon" class="text-center" style="color: red">
      Areas of Expertise Icon Can't be empty
    </p>
    <div class="margin-20-bottom">
      <b-form-input
        placeholder="Title"
        v-model="areaOfExperties.header"
      ></b-form-input>
      <p
        v-if="areaOfExpertiesError.header"
        class="text-center"
        style="color: red"
      >
        Areas of Expertise Title Can't be empty
      </p>
    </div>

    
    <quill-editor v-model="areaOfExperties.detail"
                ref="myQuillEditor"
                :options="editorOption"
                >
  </quill-editor>
    <p
      v-if="areaOfExpertiesError.detail"
      class="text-center"
      style="color: red"
    >
      Areas of Expertise Title Can't be empty
    </p>
    
   
    <br />
    <div class="horizontal-center">
      <b-button variant="success" @click="addAreaOfExperties"><b-spinner v-if="loading"  small></b-spinner>Edit</b-button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { pathFieldWithOutId } from "../../assets/js/service";
import ImagPicker from "../Common/ImageUpload.vue";
export default {
  components: {
    ImagPicker,
  },
  watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },

  },
  data() {
    return {
      loading:false,
      areaOfExperties: {
        detail: null,
        icon: null,
        header: null,
      },
      areaOfExpertiesError: {
        detail: false,
        icon: false,
        header: false,
      },
      detail: null,
      linkText: null,
      link: null,
    };
  },
  mounted() {
    if (this.$store.state.singlePageData) {
      this.areaOfExperties = this.$store.state.singlePageData;
    } else {
      this.$router.go(-1);
    }
  },
  methods: {
        textBold(){
      if(!this.bold){
this.areaOfExperties.detail += '<b>'
        this.bold =true
      }else{
        this.areaOfExperties.detail += '</b>'
        this.bold = false
      }

    },
      textItalic(){
      if(!this.italic){
this.areaOfExperties.detail += '<i>'
        this.italic =true
      }else{
        this.areaOfExperties.detail += '</i>'
        this.italic = false
      }

    },
    addBreak(){
this.areaOfExperties.detail += '<br>'
    },
    changeHtmlElement() {
      detail.split("");
    },
    addLink() {
      if (this.link && this.linkText) {
        this.areaOfExperties.detail =
          this.areaOfExperties.detail +
          '<a href="' +
          this.link +
          '">' +
          this.linkText +
          "</a>";
      }
    },
    addAreaOfExperties() {
      if (!this.areaOfExperties.detail) {
        this.areaOfExpertiesError.detail = true;
      } else {
        this.areaOfExpertiesError.detail = false;
      }
      if (!this.$store.state.images || this.$store.state.images.length == 0) {
        
      } else {
        this.areaOfExperties.icon = this.$store.state.images;
        this.areaOfExpertiesError.icon = false;
      }
      if (!this.areaOfExperties.header) {
        this.areaOfExpertiesError.header = true;
      } else {
        this.areaOfExpertiesError.header = false;
      }
      if (
        this.areaOfExperties.detail &&
        
        this.areaOfExperties.header
      ) {
        this.$store.commit("set", ["loading", true]);
        
        let token = localStorage.getItem("token");
        pathFieldWithOutId("areaOfExperties", this.areaOfExperties, token).then(
          (resp) => {
            this.areaOfExperties = resp.data;
            this.loading = false;
            Vue.$toast.success("Successful", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        );
      }
    },
  },
};
</script>
<style>
</style>